import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import styles from './styles.module.scss';

type InterlocutorInfoProps = ComponentHasClassName & {
  type: string;
  name: string;
};

const InterlocutorInfo = ({ className, type = 'Interlocutor', name }: InterlocutorInfoProps) => (
  <Text
    className={cx(className, styles.root)}
    size="xl"
  >
    Your {type}: {name}
  </Text>
);

export type { InterlocutorInfoProps };
export { InterlocutorInfo };
