import Text from '@swe/shared/ui-kit/components/text';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { formatPhone } from '@swe/shared/utils/phone';
import cx from 'clsx';

import styles from './styles.module.scss';

type ChatClosedAlertProps = ComponentHasClassName & {
  storePhoneNumber?: string;
};

const ChatClosedAlert = ({ className, storePhoneNumber }: ChatClosedAlertProps) => {
  return (
    <div className={cx(className, styles.root)}>
      <Text
        variant="headline"
        size="xs"
      >
        Chat has been closed
      </Text>
      {storePhoneNumber && <Text size="lg">Shop number: {formatPhone(storePhoneNumber)}</Text>}
    </div>
  );
};

export type { ChatClosedAlertProps };
export { ChatClosedAlert };
