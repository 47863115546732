import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ChatId } from 'entities/profile/orders';

type Params = { chatId: ChatId; text: string; clientMessageId: ChatId };
type Data = undefined;

const NAME = '/Chats/SendChatMessage';
const SendChatMessage = createModernEndpoint<Params, Data>(NAME);

export { SendChatMessage };
export default SendChatMessage;
