import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ChatId, ChatMessage } from 'entities/profile/orders';

type Params = { chatId: ChatId; messages: ChatMessage['messageId'][] };
type Data = undefined;

const NAME = '/Chats/ReadChatMessages';
const ReadChatMessage = createModernEndpoint<Params, Data>(NAME, { notifyWithSnackbar: false });

export { ReadChatMessage };
export default ReadChatMessage;
