import { useCallback, useEffect, useRef } from 'react';

import ReadChatMessage from 'endpoints/profile/orders/read-chat-message';
import { ChatId } from 'entities/profile/orders';

const INTERVAL_EMPTY_BATCH_LIMIT = 3;
const INTERVAL_TIMEOUT = 3000;

type UseChatMessageReaderOptions = {
  chatId: ChatId;
  isActive?: boolean;
};

const useChatMessageReader = ({ chatId, isActive = true }: UseChatMessageReaderOptions) => {
  const batch = useRef<string[]>([]);
  const intervalId = useRef<number | undefined>();
  const timeout = useRef(0);

  const shutdownInterval = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      batch.current = [];
      intervalId.current = undefined;
      timeout.current = 0;
    }
  }, []);
  const trySendReadMessages = useCallback(async () => {
    if (timeout.current === INTERVAL_EMPTY_BATCH_LIMIT) {
      shutdownInterval();
      timeout.current = 0;
      return;
    }

    if (batch.current.length === 0) {
      timeout.current += 1;
      return;
    }

    try {
      await ReadChatMessage.request({ chatId, messages: batch.current });
      batch.current = [];
    } catch (e) {
      console.error(e);
    }
  }, [chatId, shutdownInterval]);
  const setupInterval = useCallback(() => {
    intervalId.current = setInterval(trySendReadMessages, INTERVAL_TIMEOUT) as unknown as number;
  }, [trySendReadMessages]);
  const handleMessageRead = useCallback(
    (messageId: string) => {
      if (!intervalId.current) {
        setupInterval();
      }

      batch.current.push(messageId);
    },
    [setupInterval],
  );

  useEffect(() => {
    if (isActive) {
      return () => {
        void trySendReadMessages();
        shutdownInterval();
      };
    }
  }, [isActive, trySendReadMessages, shutdownInterval]);

  return {
    handleMessageRead,
  };
};

export { useChatMessageReader };
