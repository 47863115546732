import { useDocumentHeight } from '@swe/shared/hooks/use-document-height';
import { useIsomorphicLayoutEffect } from '@swe/shared/hooks/use-isomorphic-layout-effect';
import { useIsIOS } from '@swe/shared/tools/media';
import { Hero } from '@swe/shared/ui-kit/components/hero';
import { ChatIllustration } from '@swe/shared/ui-kit/components/illustration';
import Loader from '@swe/shared/ui-kit/components/loader';
import { Observable } from '@swe/shared/ui-kit/components/observer';
import Text from '@swe/shared/ui-kit/components/text';
import cx from 'clsx';

import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';

import { ChatClosedAlert } from 'domains/profile/containers/chat/components/chat-closed-alert';
import { InterlocutorInfo } from 'domains/profile/containers/chat/components/interlocutor-info';
import { Message, MessageProps } from 'domains/profile/containers/chat/components/message';
import { isInterlocutorInfo, MessageStreamRef } from 'domains/profile/containers/chat/components/message-stream/types';
import { isEndOfScroll, splitByDay } from 'domains/profile/containers/chat/components/message-stream/utils';

type MessageStreamProps = {
  messages: MessageProps[];
  canLoadBack?: boolean;
  isClosed?: boolean;
  storePhoneNumber?: string;
  onLoadPrevious: (page: number) => MessageProps[] | Promise<MessageProps[]>;
  onScrolledToEnd?: () => void;
  onMessageRead?: (messageId: string) => void;
};

const MessageStream = forwardRef<MessageStreamRef, MessageStreamProps>(
  (
    { messages, canLoadBack = true, isClosed, storePhoneNumber, onLoadPrevious, onScrolledToEnd, onMessageRead },
    ref,
  ) => {
    const isIOS = useIsIOS();
    const rootRef = useRef<HTMLDivElement>(null!);
    const currentPage = useRef(1);
    const beforeUnshiftScrollState = useRef<ReturnType<typeof getScroll>>({
      scrollHeight: 0,
      scrollTop: 0,
      isScrolledToBottom: true,
    });
    const [canLoadMore, setCanLoadMore] = useState(canLoadBack);
    const [previousMessages, setPreviousMessages] = useState<MessageProps[]>([]);
    const messagesByDay = useMemo(
      () => Array.from(splitByDay([...previousMessages, ...messages]).entries()),
      [messages, previousMessages],
    );
    const { actualHeight, previousHeight } = useDocumentHeight();

    useEffect(() => {
      if (actualHeight !== previousHeight) {
        rootRef.current?.scroll({
          top: rootRef.current.scrollTop + Math.abs(actualHeight - previousHeight),
          behavior: 'auto',
        });
      }
    }, [actualHeight, previousHeight]);

    const scrollBottom = useCallback((smooth = false) => {
      rootRef.current?.scrollBy({ top: rootRef.current.scrollHeight, behavior: smooth ? 'smooth' : undefined });
    }, []);

    const getScroll = useCallback(
      () => ({
        scrollTop: rootRef.current.scrollTop,
        scrollHeight: rootRef.current.scrollHeight,
        isScrolledToBottom: isEndOfScroll(
          rootRef.current.scrollHeight,
          rootRef.current.scrollTop,
          rootRef.current.offsetHeight,
        ),
      }),
      [],
    );

    const handleLoadPrevious = useCallback(async () => {
      const nextPage = currentPage.current + 1;
      currentPage.current = nextPage;
      beforeUnshiftScrollState.current = getScroll();
      const messages = await onLoadPrevious(nextPage);

      setPreviousMessages((prev) => [...messages, ...prev]);
      setCanLoadMore(messages.length > 0);
    }, [getScroll, onLoadPrevious]);

    useIsomorphicLayoutEffect(() => {
      const { scrollTop: prevScrollTop, scrollHeight: prevScrollHeight } = beforeUnshiftScrollState.current;
      const { scrollHeight } = getScroll();
      const nextScrollTop = prevScrollTop + scrollHeight - prevScrollHeight;
      rootRef.current.scroll({ top: nextScrollTop });
    }, [getScroll, previousMessages]);

    useIsomorphicLayoutEffect(() => {
      setTimeout(() => scrollBottom(), 0);
    }, [scrollBottom]);

    useEffect(() => {
      if (onScrolledToEnd) {
        const listener = (e: Event) => {
          const { scrollTop, scrollHeight, offsetHeight } = e.target as HTMLDivElement;
          if (isEndOfScroll(scrollHeight, scrollTop, offsetHeight)) {
            onScrolledToEnd();
          }
        };
        const element = rootRef.current;

        element?.addEventListener('scroll', listener, { passive: true });

        return () => {
          element?.removeEventListener('scroll', listener);
        };
      }
    }, [onScrolledToEnd]);

    useImperativeHandle(ref, () => ({
      scrollBottom,
      getScroll,
    }));

    return (
      <div
        ref={rootRef}
        className={cx(styles.root, isIOS && styles._overscrollContain)}
      >
        <div className={styles.listContainer}>
          {messagesByDay.length > 0 ? (
            <>
              {canLoadMore && (
                <Observable
                  className={cx(styles.loader, !canLoadMore && styles.loader_hidden)}
                  active={canLoadMore}
                  onReveal={handleLoadPrevious}
                >
                  <Loader size="xl" />
                </Observable>
              )}
              <div className={styles.list}>
                {messagesByDay.map(([day, messages]) => (
                  <>
                    <Text
                      key={day}
                      className={styles.day}
                      variant="headline"
                      size="xs"
                      adaptive
                    >
                      {day.toUpperCase()}
                    </Text>
                    {messages.map((message) =>
                      isInterlocutorInfo(message) ? (
                        <InterlocutorInfo
                          className={styles.managerInfo}
                          name={message.name}
                          type={message.type}
                        />
                      ) : (
                        <Message
                          {...message}
                          key={message.messageId}
                          className={styles.message}
                          onRead={onMessageRead}
                        />
                      ),
                    )}
                  </>
                ))}
                {isClosed && (
                  <ChatClosedAlert
                    className={styles.alert}
                    storePhoneNumber={storePhoneNumber}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <Hero
                className={styles.illustration}
                title={isClosed ? 'No Messages' : 'No Messages yet'}
                illustration={<ChatIllustration />}
              />
              {isClosed && (
                <ChatClosedAlert
                  className={styles.alert}
                  storePhoneNumber={storePhoneNumber}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  },
);

export type { MessageStreamProps, MessageStreamRef };
export { MessageStream };
