import { DatePresenter } from '@swe/shared/ui-kit/components/date-presenter';
import { CheckIcon, ClockIcon, CheckDoubleIcon } from '@swe/shared/ui-kit/components/icon';
import { Observable } from '@swe/shared/ui-kit/components/observer';
import SVG from '@swe/shared/ui-kit/components/svg';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import { useCallback } from 'react';

import PointerLeft from './assets/pointer-left.svg';
import PointerRight from './assets/pointer-right.svg';
import styles from './styles.module.scss';

type MessageProps = ComponentHasClassName & {
  messageId: string;
  origin: 'self' | 'interlocutor';
  // eslint-disable-next-line react/no-unused-prop-types
  sender: string;
  // eslint-disable-next-line react/no-unused-prop-types
  senderType: string;
  text: string;
  sentAt: string;
  isSent?: boolean;
  isRead?: boolean;
  onRead?: (messageId: string) => void;
};

const Message = ({ className, messageId, origin, text, sentAt, isSent, isRead, onRead }: MessageProps) => {
  const handleRead = useCallback(() => {
    onRead?.(messageId);
  }, [messageId, onRead]);

  const MessageElement = (
    <div className={cx(className, styles.root, styles[`_origin_${origin}`])}>
      <Text
        className={styles.text}
        size="lg"
      >
        {text}
      </Text>
      <Text
        size="sm"
        className={styles.meta}
      >
        {sentAt && (
          <div>
            <DatePresenter
              value={sentAt}
              format="p"
            />
          </div>
        )}
        {origin === 'self' &&
          (isRead ? (
            <CheckDoubleIcon
              size="sm"
              className={styles.metaIcon}
            />
          ) : isSent ? (
            <CheckIcon
              size="sm"
              className={styles.metaIcon}
            />
          ) : (
            <ClockIcon
              size="sm"
              className={styles.metaIcon}
            />
          ))}
      </Text>
      {origin === 'self' && (
        <SVG
          src={PointerRight}
          className={styles.pointer}
        />
      )}
      {origin === 'interlocutor' && (
        <SVG
          src={PointerLeft}
          className={styles.pointer}
        />
      )}
    </div>
  );

  return onRead && origin === 'interlocutor' && isRead === false ? (
    <Observable
      active
      deactivateAfterReveal
      onReveal={handleRead}
    >
      {MessageElement}
    </Observable>
  ) : (
    MessageElement
  );
};

export type { MessageProps };
export { Message };
