import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { useRouterQuery } from 'common/router';
import { Chat } from 'domains/profile/containers/chat';

const OrderChatPage: ApplicationPage = () => {
  const { id: orderId, chatId } = useRouterQuery();

  return (
    <Page {...DEFAULT_AUTHORIZED_PAGE_PROPS}>
      <Chat
        chatId={chatId as string}
        orderId={Number(orderId)}
      />
    </Page>
  );
};

OrderChatPage.getMeta = () => ({ title: 'Chat' });

OrderChatPage.getMainLayoutProps = () => ({
  footer: null,
  subheader: null,
  header: null,
  paddings: 'none',
  size: 'none',
});

export default OrderChatPage;
