import { MessageProps } from 'domains/profile/containers/chat/components/message';

type MessageStreamRef = {
  scrollBottom: () => void;
  getScroll: () => {
    scrollTop: number;
    scrollHeight: number;
    isScrolledToBottom: boolean;
  };
};

type InterlocutorInfoT = { name: string; type: string };

const isInterlocutorInfo = (p: MessageProps | InterlocutorInfoT): p is InterlocutorInfoT => 'name' in p && 'type' in p;

export type { MessageStreamRef, InterlocutorInfoT };
export { isInterlocutorInfo };
