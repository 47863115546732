import { useDocumentHeightVariable } from '@swe/shared/hooks/use-document-height';
import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { useMatchRoute } from '@swe/shared/providers/router/helpers';
import { useBreakpoint } from '@swe/shared/tools/media';
import { useScrollLocker } from '@swe/shared/tools/window';
import Chips from '@swe/shared/ui-kit/components/chips';
import Container from '@swe/shared/ui-kit/components/container';

import cx from 'clsx';

import { useCallback, useRef } from 'react';

import styles from './styles.module.scss';

import { useGuest } from 'common/providers/guest';
import { Routes } from 'common/router/constants';

import { Header } from 'domains/profile/containers/chat/components/header';
import { MessageInput } from 'domains/profile/containers/chat/components/message-input';
import { MessageStream, MessageStreamRef } from 'domains/profile/containers/chat/components/message-stream';
import { useLogoutGuest } from 'domains/profile/containers/order-details/use-cases/use-logout-guest';
import { useChatMessages } from 'domains/profile/use-cases/use-chat-messages';
import useOrderDetails, { getOrderNumber } from 'domains/profile/use-cases/use-order-details';
import { ChatId, OrderId } from 'entities/profile/orders';

type ChatProps = {
  chatId: ChatId;
  orderId: OrderId;
};

const Chat = ({ chatId, orderId }: ChatProps) => {
  const messageStreamRef = useRef<MessageStreamRef>(null!);
  const [isNewMessageNotifierVisible, showNewMessageNotifier, closeNewMessageNotifier] = useToggleable();
  const { lessThan } = useBreakpoint();
  const { order, getChatById, contacts } = useOrderDetails(orderId);
  const chat = getChatById(chatId);

  const scrollChatToBottom = useCallback(() => {
    setTimeout(() => {
      messageStreamRef.current.scrollBottom();
    }, 50);
  }, []);
  const handleNewMessageClick = useCallback(() => {
    scrollChatToBottom();
    closeNewMessageNotifier();
  }, [closeNewMessageNotifier, scrollChatToBottom]);
  const handleNewMessage = useCallback(() => {
    if (!messageStreamRef.current.getScroll().isScrolledToBottom) {
      showNewMessageNotifier();
    }
  }, [showNewMessageNotifier]);

  const { messages, sendMessage, loadMessages, handleMessageRead, isLoading } = useChatMessages({
    chatId,
    chatType: chat?.type,
    isReaderActive: useMatchRoute(Routes.ProfileOrderChat),
    onMessageReceived: handleNewMessage,
    onMessageSent: scrollChatToBottom,
  });

  useDocumentHeightVariable(true);

  useLogoutGuest();

  useScrollLocker(true);

  const { isGuest } = useGuest();

  if (!chat || isLoading) return null;

  return (
    <div className={cx([styles.root, styles._fixed])}>
      <Header
        chatType={chat.type}
        orderId={orderId}
        orderNumber={getOrderNumber(order)}
        shortBreadcrumbs={isGuest}
      />
      <Container
        className={styles.body}
        size="md"
        paddings={lessThan.md}
      >
        <MessageStream
          ref={messageStreamRef}
          messages={messages}
          isClosed={chat.isClosed}
          storePhoneNumber={contacts.phone}
          onLoadPrevious={loadMessages}
          canLoadBack={false}
          onScrolledToEnd={closeNewMessageNotifier}
          onMessageRead={handleMessageRead}
        />
        {!chat.isClosed && (
          <div className={styles.footer}>
            <Chips
              className={cx(styles.newMessageNotifier, isNewMessageNotifierVisible && styles.newMessageNotifier_active)}
              size="lg"
              onClick={handleNewMessageClick}
            >
              New message
            </Chips>
            <MessageInput onSubmit={sendMessage} />
          </div>
        )}
      </Container>
    </div>
  );
};

export type { ChatProps };
export { Chat };
