import { RefObject, useEffect, useMemo, useRef, useState } from 'react';

type UseObserverArgs = IntersectionObserverInit & {
  onChange: (entries: IntersectionObserverEntry[]) => void;
  onInitialize?: (observer: IntersectionObserver) => void;
  onDestruct?: (observer?: IntersectionObserver) => void;
};

const useObserver = ({
  threshold = 0,
  root = null,
  rootMargin = '0%',
  onChange,
  onInitialize,
  onDestruct,
}: UseObserverArgs) => {
  const observerRef = useRef<IntersectionObserver>();

  useEffect(() => {
    observerRef.current = new IntersectionObserver(onChange, { threshold, root, rootMargin });
    onInitialize?.(observerRef.current);

    return () => {
      onDestruct?.(observerRef?.current);
      observerRef.current?.disconnect();
      observerRef.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(threshold), root, rootMargin]);

  return observerRef;
};

type UseIntersectionArgs = IntersectionObserverInit & {
  active?: boolean;
  freezeOnceVisible?: boolean;
};

const useIntersection = (
  elementRef: RefObject<Element> | Element | null,
  {
    threshold = 0,
    root = null,
    rootMargin = '0px 0px 0px 0px',
    freezeOnceVisible = false,
    active = true,
  }: UseIntersectionArgs,
) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const entryRef = useRef<IntersectionObserverEntry>();

  const isIntersecting = useMemo(() => entry?.isIntersecting, [entry]);

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  useEffect(() => {
    const node = elementRef && 'current' in elementRef ? elementRef?.current : elementRef; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node || !active) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(([entry]) => {
      setEntry(entry);
      entryRef.current = entry;
    }, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen, active]);

  return { entry, entryRef, isIntersecting };
};

const useIntersectionWithRef = (options: UseIntersectionArgs = {}) => {
  const [rootRef, setRootRef] = useState<HTMLElement | null>(null);
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null);

  const intersection = useIntersection(elementRef, { ...options, root: rootRef });

  return {
    ...intersection,
    setRootRef,
    setElementRef,
  };
};

export { useObserver, useIntersection, useIntersectionWithRef };
