import { tryFormatCloseDate } from '@swe/shared/utils/date';

import { MessageProps } from 'domains/profile/containers/chat/components/message';
import { InterlocutorInfoT } from 'domains/profile/containers/chat/components/message-stream/types';

const isEndOfScroll = (scrollHeight: number, scrollTop: number, offsetHeight: number) =>
  Math.abs(scrollHeight - (offsetHeight + scrollTop)) < 1;

const splitByDay = (messages: MessageProps[]) => {
  const map = new Map<string, (MessageProps | InterlocutorInfoT)[]>();
  let lastInterlocutor = '';

  const checkManager = (message: MessageProps, onNewManagerFound: (name: string, type: string) => void) => {
    if (message.origin !== 'self' && message.sender !== lastInterlocutor) {
      lastInterlocutor = message.sender;
      onNewManagerFound(message.sender, message.senderType);

      return !!message.sender;
    }
  };

  messages.forEach((message) => {
    const day = tryFormatCloseDate(message.sentAt, 'MMMM d');

    if (map.has(day)) {
      checkManager(message, (name, type) => map.get(day)!.push({ name, type }));
      map.get(day)!.push(message);
    } else {
      const hasNewManager = checkManager(message, (name, type) => map.set(day, [{ name, type }, message]));
      if (!hasNewManager) map.set(day, [message]);
    }
  });

  return map;
};

export { isEndOfScroll, splitByDay };
