import { useCallback, useEffect, useState } from 'react';

import { useIntersectionWithRef } from '@swe/shared/hooks/use-intersection';
import Box, { BoxProps } from '@swe/shared/ui-kit/components/box';
import { ComponentHasChildren, ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

type ObservableProps = ComponentHasClassName &
  ComponentHasChildren & {
    active?: boolean;
    onReveal: () => void | Promise<void>;
    onHide?: () => void;
    onDeactivate?: () => void;
    deactivateAfterReveal?: boolean;
    deferredActivation?: number;
  } & BoxProps<any>;

const Observable = ({
  className,
  id,
  children,
  active = true,
  onReveal,
  onHide,
  deactivateAfterReveal,
  deferredActivation,
  onDeactivate,
  ...boxProps
}: ObservableProps) => {
  const [isDisabled, setDisabled] = useState(typeof deferredActivation !== 'undefined');
  const { setElementRef, isIntersecting } = useIntersectionWithRef({ active: !isDisabled && active });

  const deactivate = useCallback(() => {
    setDisabled(true);
    onDeactivate?.();
  }, [onDeactivate]);

  useEffect(() => {
    if (isIntersecting) {
      void onReveal();
      if (deactivateAfterReveal) {
        deactivate();
      }
    } else {
      onHide?.();
    }
  }, [deactivate, deactivateAfterReveal, isIntersecting, onHide, onReveal, id]);

  useEffect(() => {
    if (deferredActivation) {
      setTimeout(() => {
        setDisabled(false);
      }, deferredActivation);
    }
  }, [deferredActivation]);

  return (
    <Box
      ref={setElementRef}
      className={className}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export type { ObservableProps };
export { Observable };
