import { Modifier, useKeyPress } from '@swe/shared/hooks/use-key-press';
import Button from '@swe/shared/ui-kit/components/button';
import Textarea from '@swe/shared/ui-kit/components/form/textarea';
import { ChevronRightIcon } from '@swe/shared/ui-kit/components/icon';
import Stack from '@swe/shared/ui-kit/components/stack';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cn from 'clsx';
import { useCallback, useRef, useState } from 'react';

import styles from './styles.module.scss';

type MessageInputProps = ComponentHasClassName & {
  onSubmit: (text: string) => Promise<void> | void;
};

const MessageInput = ({ className, onSubmit }: MessageInputProps) => {
  const areaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState('');
  const [isFocused, setFocused] = useState(false);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!text) return;

    try {
      await onSubmit(text);
      setText('');
      areaRef.current?.focus();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [onSubmit, text]);

  const bind = useKeyPress<HTMLDivElement>({
    key: 'Enter',
    modifiers: [Modifier.Meta],
    onKeyDown: handleSubmit,
  });

  return (
    <Stack
      className={cn(className, styles.stack)}
      direction="row"
      spacing="sm"
      {...bind}
    >
      <Textarea
        ref={areaRef}
        className={styles.area}
        placeholder="Write a message"
        name="message"
        minRows={3}
        maxRows={3}
        staticNote={false}
        value={text}
        onChange={setText}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Button
        className={styles.button}
        size="sm"
        color={isFocused ? 'primary' : 'light'}
        icon={ChevronRightIcon}
        onClick={handleSubmit}
        ariaLabel="Send"
      />
    </Stack>
  );
};

export type { MessageInputProps };
export { MessageInput };
