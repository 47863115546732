import { uuidv4 } from '@swe/shared/utils/uuid';

import { useCallback, useMemo, useState } from 'react';

import { useStoreConfig } from 'common/providers/config';
import { useSession } from 'common/providers/user';
import { MessageProps } from 'domains/profile/containers/chat/components/message';
import { useChatMessageReader } from 'domains/profile/use-cases/use-chat-message-reader';
import useMainHub from 'endpoints/hubs/main';
import GetChatMessagesEndpoint from 'endpoints/profile/orders/get-chat-messages';
import SendChatMessage from 'endpoints/profile/orders/send-chat-messages';
import { ChatType } from 'entities/common/orders';
import {
  CHAT_CLOSED_TEXT_MESSAGE,
  ChatId,
  ChatMessage,
  ChatMessageReader,
  getMessageSenderType,
  isMessageReadBy,
  isMessageReadByAny,
  isMessageSent,
  isSystem,
  ChatMessageStatus,
} from 'entities/profile/orders';

// TODO: Remove that piece of shit right after backend is ready to handle pointers
// MADE WITH LOVE LIKE IN THE OLD SHOP <3
const DEFAULT_PAGE_SIZE = 1000000;

const loadChatMessages = (chatId: ChatId, page: number) =>
  GetChatMessagesEndpoint.request({ chatId, page, pageSize: DEFAULT_PAGE_SIZE });

const filterSystemMessages = (chatMessage: ChatMessage) => {
  return !(isSystem(chatMessage.sender) && chatMessage.text === CHAT_CLOSED_TEXT_MESSAGE);
};

const mapChatMessageToMessageProps = (chatMessage: ChatMessage, chatType?: ChatType): MessageProps => ({
  messageId: chatMessage.messageId || chatMessage.clientMessageId,
  origin: chatMessage.sender.isClient ? 'self' : 'interlocutor',
  text: chatMessage.text,
  sentAt: chatMessage.date,
  isSent: isMessageSent(chatMessage),
  sender: chatMessage.sender.name,
  senderType: getMessageSenderType(chatMessage, chatType),
  isRead: chatMessage.sender.isClient
    ? isMessageReadByAny(chatMessage, [
        ChatMessageReader.Courier,
        ChatMessageReader.Employee,
        ChatMessageReader.SystemUser,
      ])
    : isMessageReadBy(chatMessage, ChatMessageReader.Client),
});

type UseChatMessageOptions = {
  chatId: ChatId;
  chatType?: ChatType;
  isReaderActive?: boolean;
  onMessageSent?: () => void;
  onMessageReceived?: (msg: ChatMessage) => void;
};

const useChatMessages = ({
  chatId,
  chatType,
  onMessageSent,
  onMessageReceived,
  isReaderActive,
}: UseChatMessageOptions) => {
  const { id } = useStoreConfig();
  const { handleMessageRead } = useChatMessageReader({
    chatId,
    isActive: isReaderActive,
  });
  const { data, isLoading } = GetChatMessagesEndpoint.useRequest(
    {
      chatId,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    undefined,
    { dedupingInterval: 1000 },
  );
  const initialMessages = useMemo(
    () =>
      (data?.messages ?? [])
        .filter(filterSystemMessages)
        .map((message) => mapChatMessageToMessageProps(message, chatType)),
    [chatType, data?.messages],
  );
  const [newMessages, setNewMessages] = useState<MessageProps[]>([]);

  const loadMessages = useCallback(
    async (page: number) => {
      const { messages } = await loadChatMessages(chatId, page);

      return messages.map((message) => mapChatMessageToMessageProps(message, chatType));
    },
    [chatId, chatType],
  );
  const sendMessage = useCallback(
    async (text: string) => {
      const clientMessageId = uuidv4();

      SendChatMessage.request({ chatId, clientMessageId, text })
        .then(() => {
          onMessageSent?.();
        })
        .catch(console.error);
    },
    [chatId, onMessageSent],
  );
  const onReceive = useCallback(
    (message: ChatMessage) => {
      if (message.chatId !== chatId) return;

      if ([ChatMessageStatus.Created].includes(message.status)) {
        setNewMessages((prev) => {
          return [...prev, mapChatMessageToMessageProps(message, chatType)];
        });

        if (!message.sender.isClient) {
          onMessageReceived?.(message);
        }
      }

      if ([ChatMessageStatus.Received, ChatMessageStatus.Read, ChatMessageStatus.SendFailed].includes(message.status)) {
        setNewMessages((prev) => {
          const messages = [...prev];
          const msgIndex = prev.findIndex(
            (m) => m.messageId === message.messageId || m.messageId === message.clientMessageId,
          );
          if (msgIndex !== -1) {
            messages[msgIndex] = mapChatMessageToMessageProps(message, chatType);
          }

          return messages;
        });
      }
    },
    [chatId, chatType, onMessageReceived],
  );

  const { session } = useSession();

  useMainHub({
    type: 'messageStateChanged',
    onReceive,
    params: {
      storeId: id.toString(),
      ...session,
    },
  });

  return {
    initialMessages,
    newMessages,
    messages: [...initialMessages, ...newMessages],
    loadMessages,
    sendMessage,
    handleMessageRead,
    isLoading,
  };
};

export { useChatMessages };
