import { useBreakpoint } from '@swe/shared/tools/media';
import Container from '@swe/shared/ui-kit/components/container';

import { useMemo } from 'react';

import styles from './styles.module.scss';

import PageHeader from 'common/components/page-header';
import { getBreadcrumbTitle, Routes } from 'common/router/constants';
import { ChatType } from 'entities/common/orders';
import { OrderId } from 'entities/loyalty/info';

type HeaderProps = {
  chatType: ChatType;
  orderId: OrderId;
  orderNumber: string;
  shortBreadcrumbs?: boolean;
};

const getLabel = (type: ChatType) => ({ [ChatType.Store]: 'Dispensary Chat', [ChatType.Driver]: 'Driver Chat' })[type];

const Header = ({ chatType, orderId, orderNumber, shortBreadcrumbs }: HeaderProps) => {
  const { lessThan } = useBreakpoint();
  const breadcrumbs = useMemo(
    () => [
      ...(!shortBreadcrumbs
        ? [
            { text: getBreadcrumbTitle(Routes.Profile), href: Routes.Profile },
            { text: getBreadcrumbTitle(Routes.ProfileOrders), href: Routes.ProfileOrders },
          ]
        : []),
      {
        text: `Order ${orderNumber}`,
        href: Routes.ProfileOrderDetails.replace('[id]', orderId.toString()) as Routes.ProfileOrderDetails,
      },
      { text: getLabel(chatType) },
    ],
    [chatType, orderId, orderNumber, shortBreadcrumbs],
  );

  return (
    <div className={styles.root}>
      <Container
        className={styles.headerContainer}
        size="md"
        paddings={lessThan.md}
      >
        <PageHeader
          title={getLabel(chatType)}
          info={`Order #${orderNumber}`}
          defaultBackRoute={{ pathname: Routes.ProfileOrderDetails, query: { id: String(orderId) } }}
          breadcrumbs={breadcrumbs}
        />
      </Container>
    </div>
  );
};

export type { HeaderProps };
export { Header };
