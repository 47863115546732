import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PaginatedRequest, PaginatedResponse } from '@swe/shared/types/wrappers/pagination';

import { ChatId, ChatMessage } from 'entities/profile/orders';

type Params = PaginatedRequest & { chatId: ChatId };
type Data = PaginatedResponse<ChatMessage> & { messages: ChatMessage[] };

const NAME = '/Chats/GetChatMessagesList';
const GetChatMessages = createModernEndpoint<Params, Data>(NAME);

export { GetChatMessages };
export default GetChatMessages;
